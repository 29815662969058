import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import AlertMessage from '../Components/AlertMessage';
import { userLogin } from '../Redux/Auth/action';
import logo from '../Resources/Logo/omme-logo.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colorScheme = {
    primaryColor: '#e5322d',
    secondaryColor: 'white'
  };

  const [show, setStatus] = useState({
    status: false,
    msg: '',
    type: 'error'
  });

  const [data, setData] = useState({
    email: '',
    password: ''
  })

  const handleLogin = (e) => {
    e.preventDefault();
    if (data.email && data.password) {
      const payload = {
        email: data.email,
        password: data.password
      }
      dispatch(userLogin(payload)).then((res) => {
        if (res.payload.status) {
          setStatus({ ...show, status: true, msg: res.payload.msg, type: "success" });
          setTimeout(() => {
            navigate("/admin/dashboard");
          }, 2000)
        } else {
          setStatus({ ...show, status: true, msg: res.payload.msg, type: "error" });
        }
      }).catch((err) => {
        setStatus({ ...show, status: true, msg: "Invalid Credentials", type: "error" });
      });
    } else {
      setStatus({ ...show, status: true, msg: 'Please fill all the details' });
    }
  }

  return (
    <Container colorScheme={colorScheme}>
      <div className='logoBox'>
        <img src={logo} alt="omme" />
      </div>

      <form action="">
        <div className='LoginBox'>
          <div className='LoginInput'>
            <label htmlFor="">Email Address <span>*</span></label>
            <input type="email" required placeholder='Enter Email' onChange={(e) => setData({ ...data, email: e.target.value })} />
          </div>

          <div className='LoginInput'>
            <label htmlFor="">Password <span>*</span></label>
            <input type="password" required placeholder='Enter Password' onChange={(e) => setData({ ...data, password: e.target.value })} />
          </div>
          <div className='LoginInput'>
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      </form>
      <AlertMessage show={show} setStatus={setStatus} />
    </Container>
  )
}
const Container = styled.div`
  width:100%;
  height:80vh;
  margin:auto;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  gap:10px;

  .logoBox img{
    width:120px;
    height:120px;
  }

  .LoginBox{
    width:300px;
    padding:20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius:8px;
  }

  .LoginInput{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding:10px;
  }

  .LoginInput label{
    font-weight:bold;
  }

  .LoginInput label span{
    color:${props => props.colorScheme.primaryColor};
  }

  .LoginInput input{
    width:90%;
    margin-top:5px;
    border-radius:8px;
    border:1px solid grey;
    padding:9px;
  }

  .LoginInput button{
    width:97%;
    height:35px;
    margin-top:5px;
    border:none;
    background-color:${props => props.colorScheme.primaryColor};
    color:${props => props.colorScheme.secondaryColor};
    border-radius:8px;
    cursor:pointer;
    font-weight:bold;
  }

  @media only screen and (min-width:320px) and (max-width:480px){
    .LoginBox{
      width:270px;
    }
  }

  @media only screen and (max-width: 320px){
    .LoginBox{
      width:250px;
    }
    
  }
`
export default Login