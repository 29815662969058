import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Aboutus from '../Components/Aboutus';
import ContactUs from '../Components/ContactUs';
import Design from '../Components/Design';
import PrivateRoute from '../Components/PrivateRoute';
import Service from '../Components/Service';
import SingleProduct from '../Components/SingleProduct';
import CustomizedProductPage from './CustomizedProductPage';
import Dashboard from './Dashboard';
import Homepage from './Homepage';
import Login from './Login';
import ProductsPage from './ProductsPage';
import Signup from './Signup';

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Homepage />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signup' element={<Signup />} />
      <Route path='/products' element={<ProductsPage />} />
      <Route path='/products/:name' element={<SingleProduct />} />
      <Route path='/about-us' element={<Aboutus />} />
      <Route path='/customized-machine' element={<CustomizedProductPage />} />
      <Route path='/customized-machine/:name' element={<SingleProduct />} />
      <Route path='/services' element={<Service />} />
      <Route path='/design' element={<Design />} />
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='admin/dashboard' element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      } />

      <Route path='*' element={<Homepage />} />
    </Routes>
  )
}

export default AllRoutes