import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import logo from '../Resources/Logo/omme-logo.png';
import { Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userSignup } from '../Redux/Auth/action';
import AlertMessage from '../Components/AlertMessage';
const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const colorScheme = {
    primaryColor: '#e5322d',
    secondaryColor: 'white'
  }

  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    checkPassword: '',
    phone: ''
  })

  const [show, setStatus] = useState({
    status: false,
    msg: '',
    type: 'error'
  })


  const handleSignup = (e) => {
    e.preventDefault();
    if (data.password === data.checkPassword && data.email !== '' && data.password !== '' && data.checkPassword !== '' && data.name !== "" && data.phone !== "") {
      const payload = {
        email: data.email,
        name: data.name,
        password: data.password,
        phone: data.phone
      }
      dispatch(userSignup(payload)).then((res) => {
        if (res.payload.status) {
          setStatus({ ...show, status: true, msg: res.payload.msg, type: "success" });
          setTimeout(() => {
            navigate("/login");
          }, 2000)
        } else {
          setStatus({ ...show, status: true, msg: res.payload.msg, type: "error" });
        }
      }).catch(err => {
        setStatus({ ...show, status: true, msg: err.payload, type: "error" });
      })
    }
    else {
      if (data.email === '' || data.password === '' || data.checkPassword === '' || data.name === "" || data.phone === "") {
        setStatus({ ...show, status: true, msg: 'Please fill all the details' });
      }
      else {
        setStatus({ ...show, status: true, msg: 'Password does not match' });
      }
    }
  }

  return (
    <Container colorScheme={colorScheme}>
      <div className='logoBox'>
        <Link to='/'>
          <img src={logo} alt="omme" />
        </Link>
      </div>

      <form action="">
        <div className='SignupBox'>
          <div className='SignupInput'>
            <label htmlFor="">Name <span>*</span></label>
            <input type="text" required placeholder='Enter Name' value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
          </div>

          <div className='SignupInput'>
            <label htmlFor="">Email Address <span>*</span></label>
            <input type="email" required placeholder='Enter Email' value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
          </div>
          { }
          <div className='SignupInput'>
            <label htmlFor="">Password <span>*</span></label>
            <input type="password" required placeholder='Enter Password' value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} />
          </div>
          {data.password && data.password.length < 6 && <Alert className='alertBox' severity="error">Passwords must be at least 8 characters long</Alert>}
          <div className='SignupInput'>
            <label htmlFor="">Re-Enter Password <span>*</span></label>
            <input type="password" required placeholder='Enter Password' value={data.checkPassword} onChange={(e) => setData({ ...data, checkPassword: e.target.value })} />
          </div>

          {data.password && data.password !== data.checkPassword && data.checkPassword.length >= 1 && <Alert className='alertBox' severity="error">Password do not match</Alert>}
          <AlertMessage show={show} setStatus={setStatus}/>
          <div className='SignupInput'>
            <label htmlFor="">Phone Number <span>*</span></label>
            <input type="number" required placeholder='Enter Your Number' value={data.phone} onChange={(e) => setData({ ...data, phone: e.target.value })} />
          </div>

          <div className='SignupInput'>
            <button onClick={handleSignup}>Signup</button>
          </div>
        </div>
      </form>

    </Container>
  )
}
const Container = styled.div`
  width:100%;
  height:95vh;
  margin:auto;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  gap:10px;
  position:relative;
  padding:20px 0px;

  .logoBox img{
    width:120px;
    height:120px;
  }

  .SignupBox{
    width:350px;
    padding:20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius:8px;
    margin:auto;
  }

  .SignupInput{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding:10px;
  }

  .SignupInput label{
    font-weight:bold;
  }

  .SignupInput label span{
    color:${props => props.colorScheme.primaryColor};
  }

  .SignupInput input{
    width:95%;
    margin-top:5px;
    border-radius:8px;
    border:1px solid grey;
    padding:9px;
  }

  .alertBox{
    width:87%;
    margin:auto;
    font-size:small;
    padding:2px 12px;
    align-items:center;
  }

  .SignupInput button{
    width:97%;
    height:35px;
    margin:auto;
    margin-top:5px;
    border:none;
    background-color:${props => props.colorScheme.primaryColor};
    color:${props => props.colorScheme.secondaryColor};
    border-radius:8px;
    cursor:pointer;
    font-weight:bold;
  }

  @media only screen and (min-width:481px) and (max-width:767px){

    .alertBoxTop{
      top:2%;
      left:43%;
      transform: translate(-50%, -50%);
    }
  }
  

  @media only screen and (min-width:320px) and (max-width:480px){
    .SignupBox{
      width:270px;
    }

    .alertBox{
      width:87%;
      margin:auto;
      font-size:x-small;
      padding:2px 12px;
      align-items:center;
    }

    .alertBox svg{
      margin-right:-7px;
    }

    .alertBoxTop{
      left:33%;
      transform: translate(-50%, -50%);
    }
  }

  @media only screen and (max-width: 320px){
    .SignupBox{
      width:250px;
    }

    .alertBoxTop{
      width:245px;
      left:36vw;
      right:0vw
      font-size:x-small;
    }
    
  }
`

export default Signup