import axios from "axios";
import * as types from './actionTypes';

const { REACT_APP_COMPANY_URL } = process.env;
const getCompanyData = (payload) => (dispatch) => {
    dispatch({ type: types.GET_DATA_REQUEST });
    return axios.get(`${REACT_APP_COMPANY_URL}/data/companydata`, payload).then((res) => {
        return dispatch({ type: types.GET_DATA_SUCCESS, payload: res.data });
    }).catch((err) => {
        return dispatch({ type: types.GET_DATA_FAILURE, payload: err });
    })
}

const postCompanyData = (payload, headers) => (dispatch) => {

    dispatch({ type: types.POST_DATA_REQUEST });
    return axios.post(`${REACT_APP_COMPANY_URL}/data/UpdateCompanyInfo`, payload, headers).then((res) => {
        return dispatch({ type: types.POST_DATA_SUCCESS, payload: res.data });
    }).catch((err) => {
        return dispatch({ type: types.POST_DATA_FAILURE, payload: err });
    })
}

const addImages = (payload, headers) => (dispatch) => {
    dispatch({ type: types.POST_DATA_REQUEST });
    //return axios.post(`${REACT_APP_COMPANY_URL}/data/images/upload`, payload, headers).then((res) => {
    return axios.post(`${REACT_APP_COMPANY_URL}/data/UploadImages`, payload, headers).then((res) => {
        return dispatch({ type: types.POST_DATA_SUCCESS, payload: res.data });
    }).catch((err) => {
        return dispatch({ type: types.POST_DATA_FAILURE, payload: err });
    })
}

const getProducts = (payload) => (dispatch) => {
    dispatch({ type: types.GET_PRODUCT_DATA_REQUEST });
    return axios.get(`${REACT_APP_COMPANY_URL}/data/products`).then((res) => {
        return dispatch({ type: types.GET_PRODUCT_DATA_SUCCESS, payload: res.data });
    }).catch((err) => {
        return dispatch({ type: types.GET_PRODUCT_DATA_FAILURE, payload: err });
    })
}

const productDelete = (payload, headers) => (dispatch) => {
    dispatch({ type: types.DELETE_DATA_REQUEST });
   //return axios.delete(`${REACT_APP_COMPANY_URL}/data/product/delete/${payload.id}`, headers).then((res) => {
    return axios.delete(`${REACT_APP_COMPANY_URL}/data/DeleteImages/${payload.id}`, headers).then((res) => {
        return dispatch({ type: types.DELETE_DATA_SUCCESS, payload: res.data });
    }).catch((err) => {
        return dispatch({ type: types.DELETE_DATA_FAILURE, payload: err });
    })
}

const editImages = (id, payload, headers) => (dispatch) => {
    dispatch({ type: types.PATCH_DATA_REQUEST });
    //return axios.patch(`${REACT_APP_COMPANY_URL}/data/product/update/${id}`, payload, headers).then((res) => {
    return axios.patch(`${REACT_APP_COMPANY_URL}/data/UpdateImages/${id}`, payload, headers).then((res) => {
        return dispatch({ type: types.PATCH_DATA_SUCCESS, payload: res.data });
    }).catch((err) => {
        return dispatch({ type: types.PATCH_DATA_FAILURE, payload: err });
    })
}



export { getCompanyData, postCompanyData, addImages, getProducts, productDelete, editImages }