import React, { useEffect } from 'react'
import styled from 'styled-components'
import Carousel from '../Components/Carousel'
import h12 from '../Resources/Carousel/1/2.jpg'
import h13 from '../Resources/Carousel/1/3.jpg'
import h14 from '../Resources/Carousel/1/4.jpg'
import EastIcon from '@mui/icons-material/East';
import Head from '../Components/Head'
import { useSelector } from 'react-redux'
import Stats from '../Components/Stats'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
const { REACT_APP_COMPANY_URL } = process.env;
const Homepage = () => {
    const { products } = useSelector((store) => (store.AppReducer));
    const colorScheme = {
        primaryColor: '#172a88',
        secondaryColor: 'white'
    }
    return (
        <>
            <Container colorScheme={colorScheme}>
                <Carousel images={products} cat={'Home Carousel1'} />
                <div className='products-services'>
                    <Head subHead={'Our Products & Services'} heading={'We Provide'} />
                    <div className='productBox'>
                        <div className='leftBox-product'>
                            {products.map((ele, index) => (
                                ele.category === 'We Provide Left' &&
                                <Link key={index} to={`/products/${ele.name.replace(/\s+/g, '-')}`} state={{ id: ele.name, category: 'Products', loc: 'home' }}>
                                    <img src={`${process.env.REACT_APP_COMPANY_URL}${ele.img_path}`} alt={`img${index}`} />
                                    <h4>{ele.name}</h4>
                                    <span><EastIcon /></span>
                                </Link>
                            ))}
                        </div>
                        <div className='centerBox-product'>
                            {products.map((ele, index) => (
                                ele.category === 'We Provide Center' &&
                                <Link key={index} to={`/products/${ele.name.replace(/\s+/g, '-')}`} state={{ id: ele.name, category: 'Products', loc: 'home' }}>
                                    <h2>{ele.name}</h2>
                                    <p>{ele.description}</p>
                                    <span><EastIcon /></span>
                                    <img src={`${process.env.REACT_APP_COMPANY_URL}${ele.img_path}`} alt={`img${index}`} />
                                </Link>
                            ))}
                        </div>
                        <div className='rightBox-product'>
                            {products.map((ele, index) => (
                                ele.category === 'We Provide Right' &&
                                <Link key={index} to={`/products/${ele.name.replace(/\s+/g, '-')}`} state={{ id: ele.name, category: 'Products', loc: 'home' }}>
                                    <img src={`${process.env.REACT_APP_COMPANY_URL}${ele.img_path}`} alt={`img${index}`} />
                                    <h4>{ele.name}</h4>
                                    <span><EastIcon /></span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <Head subHead={'Advantages'} heading={'Why Choose Us'} />
                    <Carousel images={products} cat={'Why Choose Us'} />
                </div>

                <Customer colorScheme={colorScheme}>
                    <Swiper
                        slidesPerView={6}
                        loop={true}
                        modules={[Autoplay]}
                        autoplay={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween:10
                            },
                            480: {
                                slidesPerView: 2,
                                spaceBetween:10

                            },
                            640: {
                                slidesPerView: 6,
                                spaceBetween:10
                            }
                        }}
                        className="mySwiper"
                    >
                        {products.map((ele, index) => (
                            ele.category === 'Customers' &&
                            <SwiperSlide key={index}>
                                <img src={`${REACT_APP_COMPANY_URL}${ele.img_path}`} alt={`img${index}`} width={'100%'} height={'100%'} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Customer>

                <div>
                    <Head heading={'About Us'} />
                    <Stats />
                </div>
            </Container>
        </>
    )
}

const Customer = styled.div`
    margin:80px 0px 20px 0px;
    width:100%;
    height:12vh;
    .mySwiper{
        
    }

    img{
        border:1px solid red;
        width:200px;
        height:100px;
        object-fit: contain;
        border:1px solid #dcdcdc;
    }

    .swiper-button-prev, .swiper-button-next {
        color: ${props => props.colorScheme.primaryColor};
    }

    @media only screen and (min-width: 481px) and (max-width:768px){
        .mySwiper{
            height:50vh;
        }
    
        img{
            object-fit: cover;
        }
    }

    @media only screen and (min-width:320px) and (max-width:480px){
        .mySwiper{
            height:30vh;
        }
    
        img{
            object-fit: cover;
        }
    }

    @media only screen and (max-width: 320px){
        
        .mySwiper{
            height:20vh;
        }
    
        img{
            object-fit: cover;
        }
    }
`
const Container = styled.div`
    width:100%;
    margin-top:60px;
    cursor:pointer;
    margin-bottom:50px;

    .products-services{
        width:100%;
        background-color:#eaeaea;
        padding-bottom:10px;
    }

    .headingBox{
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        line-height:20px;
        text-transform : uppercase;
    }

    .headingBox > span{
        color:${props => props.colorScheme.primaryColor};
        font-weight:600;
    }

    .productBox{
        width:100%;
        height:600px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        overflow:hidden;
        gap:10px;
    }

    .productBox img{
        width:250px;
        height:250px;
    }

    .leftBox-product, .rightBox-product{
        display:flex;
        flex-direction:column;
        width:25%;
        height:100%;
        gap:10px;
    }

    .leftBox-product img, .rightBox-product img{
        
        object-fit:contain;
    }
    .productBox > .centerBox-product  img{
        
        object-fit:contain;
    }


    .leftBox-product>a, .rightBox-product>a{
        width:100%;
        height:300px;
        display:flex;
        background-color:white;
        align-items:flex-end;
        justify-content:flex-end;
        flex-direction:column;
        gap:10px;
        position:relative;
    }

    .leftBox-product>a>h4, .rightBox-product>a>h4{
        position:absolute;
        top:1%;
        left:5%;
        font-size:large;
        color:${props => props.colorScheme.primaryColor};
    }

    .leftBox-product>a>span, .rightBox-product>a>span{
        position:absolute;
        top:20%;
        left:5%;
        font-size:large;
        padding:2px 30px;
        border-radius:30px;
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:${props => props.colorScheme.primaryColor};
        color:white;
    }

    .leftBox-product>a>span>svg, .rightBox-product>a>span>svg{
        font-size:x-large;
    }

    .centerBox-product{
        width:50%;
        height:100%; 
        color:white;
    }

    .centerBox-product a{
        width:100%;
        height:600px; 
        background-color: white;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        gap:15px;
        overflow:hidden;
        text-decoration:none;
    }

    .centerBox-product h2{
        width:70%;
        text-align:center;
        color:${props => props.colorScheme.primaryColor};
        margin:auto;
        padding-top:80px;
    }

    .centerBox-product p{
        width:80%;
        text-align:center;
        color:black;
        margin:auto;
        line-height:20px;
        font-weight:bold;
    }

    .centerBox-product span{
        width:10%;
        font-size:large;
        padding:2px 30px;
        border-radius:30px;
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:${props => props.colorScheme.primaryColor};
        color:${props => props.colorScheme.secondaryColor};
        
    }

    .centerBox-product img{
        margin-top:-60px;
    }

    .productBox > .centerBox-product  img{
        width:250px;
        height:500px; 
    }

    @media only screen and (min-width: 769px) and (max-width:1110px){
        .productBox{
            flex-direction:column;
            height:100%;
           
        }

        .leftBox-product, .rightBox-product{
            width:90%;
            gap:10px;
            align-items:center;
            flex-direction:row;
        }
        .centerBox-product{
            width:90%;
            height:100%; 
        }

        .productBox > div > img{
            width:95%;
            height:100%;
        }
    }

    @media only screen and (min-width: 481px) and (max-width:768px){
        .productBox{
            flex-direction:column;
            height:100%;
        }

        .leftBox-product, .rightBox-product{
            width:100%;
            gap:10px;
            align-items:center;
            flex-direction:row;
        }
        .centerBox-product{
            width:100%;
            height:100%; 
        }

        .productBox > div > img{
            width:95%;
            height:100%;
        }
    }

    @media only screen and (min-width:320px) and (max-width:480px){
        .productBox{
            flex-direction:column;
            height:100%;
        }

        .leftBox-product, .rightBox-product{
            width:100%;
            gap:10px;
            align-items:center;
        }
        .centerBox-product{
            width:100%;
            height:100%; 
        }
        .productBox > div > img{
            width:95%;
            height:100%;
        }

        .leftBox-product img, .rightBox-product img{
            width:200px;
            height:250px;
            object-fit:contain;
        }
        .productBox > .centerBox-product  img{
            width:250px;
            height:400px; 
            object-fit:contain;
            margin-top:0px;
        }
    
    }

    @media only screen and (max-width: 320px){
        
        .productBox{
            flex-direction:column;
            height:100%;
        }

        .leftBox-product, .rightBox-product{
            width:100%;
            gap:10px;
            align-items:center;
        }
        .centerBox-product{
            width:100%;
            height:100%; 
        }
        .productBox > div > img{
            width:95%;
            height:100%;
        }

        .leftBox-product img, .rightBox-product img{
            width:200px;
            height:250px;
            object-fit:contain;
        }
        .productBox > .centerBox-product  img{
            width:250px;
            height:400px; 
            object-fit:contain;
        }
    
    }
    
`

export default Homepage