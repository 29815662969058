import React from 'react'
import styled from 'styled-components'

const Head = ({ subHead = '', heading = '' }) => {
    const colorScheme = {
        primaryColor: '#172a88',
        secondaryColor: 'white'
    }
    return (
        <Container className='headingBox' colorScheme={colorScheme}>
            <span>{subHead}</span>
            <h1>{heading}</h1>
        </Container>
    )
}

const Container = styled.div`
    padding-top:50px;
    .headingBox{
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        line-height:20px;
        text-transform : uppercase;
        
    }

    .headingBox > span{
        color:${props => props.colorScheme.primaryColor};
        font-weight:600;
    }

    @media only screen and (min-width:320px) and (max-width:480px){
        font-size:small;
    }

    @media only screen and (max-width: 320px){
        font-size:small;
    }
`

export default Head