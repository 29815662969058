import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import logo from '../Resources/Logo/omme-logo.png';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';

const Navbar = () => {
    const { companyData } = useSelector((store) => (store.AppReducer));
    const [liState, setliState] = useState(Number(localStorage.getItem('current')) || 0);
    const [head, setHead] = useState(false);
    const location = useLocation();
    const menuRef = useRef()
    const navItems = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'About Us',
            path: '/about-us'
        },
        {
            name: 'Design',
            path: '/design'
        },
        {
            name: 'Products',
            path: '/products'
        },
        {
            name: 'Customized Machine',
            path: '/customized-machine'
        },
        {
            name: 'Services',
            path: '/services'
        },
        {
            name: 'Contact',
            path: '/contact-us'
        }
    ]

    const colorScheme = {
        primaryColor: '#172a88',
        secondaryColor: 'white'
    }

    const changeNavbarPosition = () => {
        if (window.scrollY >= 20 && !head) {
            setHead(true)
        }
        if (window.scrollY < 10 && head) {
            setHead(false)
        }

    };
    const check = (index) =>{
        localStorage.setItem('current', index);
        setliState(index);
    }

    window.addEventListener('scroll', changeNavbarPosition);
    useEffect(() => {
        if(location.pathname === '/'){
            setliState(0);
        }
    }, [location])

    return (
        <Container primaryColor={colorScheme.primaryColor} secondaryColor={colorScheme.secondaryColor} head={head}>
            {/* <div className='headerInfo'>
                <div className='info'>
                    <div className='logoBox'>
                        <img src={logo} alt="omme-logo" height={'40px'} width={'40px'} />
                        <span>{companyData?.companyFullName}</span>
                    </div>
                    <div className='phoneSection'>
                        <span><PhoneAndroidIcon />Phone:</span>
                        <a href={`tel:${companyData?.phone}`} ><span>{companyData?.phone}</span></a>
                    </div>
                    <div className='emailsection'>
                        <span><EmailIcon />Email:</span>
                        <a href={`mailto:${companyData?.contactemail}`}><span>{companyData?.contactemail}</span></a>
                    </div>
                </div>
            </div> */}
            <header className="header" >
                <a href="/" className="logo">
                    <div>
                        <img src={logo} alt="omme-logo" height={'40px'} width={'40px'} /><span>{companyData?.companyFullName}</span>
                    </div>
                </a>
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
                <ul className="menu" >
                    {navItems.map((ele, index) => (
                        <li key={index} ref={menuRef} className={liState === index ? "cur" : null} onClick={() => check(index)}><Link to={`${ele.path}`}>{ele.name}</Link></li>
                    ))}
                    {/* <li className='search'><Link to={`/search`}><SearchIcon /></Link></li> */}
                </ul>
            </header>
        </Container>
    )
}

const Container = styled.div`
    width:100%;
    margin:auto;
    padding-bottom:2px;
    a {
        text-decoration:none;
        color:black;
    }
    
    .cur{
        background-color:${props => props.primaryColor};
    }

    .header .menu .cur a{
       color:white;
    }

    /* header */
    
    // .headerInfo{
    //     width:100%;
    //     display:${props => props.head && 'none'};
    // }

    // .headerInfo .info{
    //     width:90%;
    //     margin:auto;
    //     display:flex};
    //     align-items:center;
    //     justify-content:space-evenly;
    //     padding:20px 5px;
    //     gap:10px;
    // }

    // .headerInfo .info > div{
    //     display:flex;
    //     align-items:center;
    //     gap:5px;
    //     margin:auto;
    // }

    // .headerInfo .info > div > span{
    //     display:flex;
    //     align-items:center;
    //     gap:5px;
    //     font-weight:600;
    // }

    // .headerInfo .info > div > span > svg{
    //     fill:${props => props.primaryColor};
    //     font-size: xx-large;
    // }
    
    .header {
        width: 100%;
        z-index: 3;
        position:fixed;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color:white;
        // margin-left:-5px;
        // margin-top:${props => props.head ? '-20px' : "15px"};
    }
    
    .header ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        text-align:center;
    }

    .search{
        margin-bottom:-10px;
    }

    .header li {
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .header li a:hover {
        color:${props => props.primaryColor};
    }
    
    .header li a {
        padding: 20px 20px;
        text-decoration: none;
        font-weight:600;
    }
    
    .header .logo {
        display: block;
        float: left;
        font-size: larger;
        padding: 10px 20px;
        text-decoration: none;
        color:black;
        font-weight:600;
    }
    .header .logo div {
        display: flex;
        align-items:center;
        gap:10px;
    }
    
    /* menu */
    
    .header .menu {
        clear: both;
        max-height: 0;
        transition: max-height .2s ease-out;
    }
    
    /* menu icon */
    
    .header .menu-icon {
        cursor: pointer;
        display: inline-block;
        float: right;
        padding: 28px 20px;
        position: relative;
        user-select: none;
    }
    
    .header .menu-icon .navicon {
        background: #333;
        display: block;
        height: 2px;
        position: relative;
        transition: background .2s ease-out;
        width: 18px;
    }
    
    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
        background: #333;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all .2s ease-out;
        width: 100%;
    }
    
    .header .menu-icon .navicon:before {
        top: 5px;
    }
    
    .header .menu-icon .navicon:after {
        top: -5px;
    }
    
    /* menu btn */
    
    .header .menu-btn {
        display: none;
    }
    
    .header .menu-btn:checked ~ .menu {
        max-height: 500px;
    }
    
    .header .menu-btn:checked ~ .menu-icon .navicon {
        background: transparent;
    }
    
    .header .menu-btn:checked ~ .menu-icon .navicon:before {
        transform: rotate(-45deg);
    }
    
    .header .menu-btn:checked ~ .menu-icon .navicon:after {
        transform: rotate(45deg);
    }
    
    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
        top: 0;
    }
    
    /* 48em = 768px */
    
    @media (min-width: 70em) {
        .header li {
            float: left;
        }
        .header li a {
            padding: 20px 30px;
        }
        .header .menu {
            clear: none;
            float: right;
            max-height: none;
        }
        .header .menu-icon {
            display: none;
        }
    }

    @media only screen and (min-width: 769px) and (max-width:1110px){
        
    }

    @media only screen and (min-width: 481px) and (max-width:768px){
        .headerInfo .info{
            width:90%;
            flex-direction:column;
            gap:10px;
            align-items:flex-start;
        }
    }

    @media only screen and (min-width:320px) and (max-width:480px){
        .headerInfo .info{
            width:90%;
            flex-direction:column;
            align-items:flex-start;
            gap:15px;
        }

        .header .logo {
            font-size: 1.5em;
            font-weight:600;
        }

        .header .logo div {
           font-size:small;
        }
    }

    @media only screen and (max-width: 320px){
        
        .headerInfo .info{
            width:90%;
            flex-direction:column;
            align-items:flex-start;
            gap:15px;
        }

        .header .logo {
            font-size: 1.5em;
            font-weight:600;
        }

        .header .logo div {
            font-size:small;
         }
    }

`

export default Navbar