import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import styled from 'styled-components';
const { REACT_APP_COMPANY_URL } = process.env;
const Carousel = ({ images, cat }) => {
    const colorScheme = {
        primaryColor: '#e5322d',
        secondaryColor: 'white'
    }
    return (
        <Container colorScheme={colorScheme}>
            <Swiper
                slidesPerView={1}
                loop={true}
                modules={[Autoplay]}
                autoplay={true}
                className="mySwiper"
            >
                {images.map((ele, index) => (
                    ele.category === cat && 
                    <SwiperSlide key={index}>
                        <img src={`${REACT_APP_COMPANY_URL}${ele.img_path}`} alt={`img${index}`} width={'100%'} height={'100%'} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container>
    )
}

const Container = styled.div`
    width:100%;
    
    .mySwiper{
        height:90vh;
    }

    img{
        object-fit: cover;
    }

    .swiper-button-prev, .swiper-button-next {
        color: ${props => props.colorScheme.primaryColor};
    }

    @media only screen and (min-width: 481px) and (max-width:768px){
        .mySwiper{
            height:50vh;
        }
    
        img{
            object-fit: cover;
        }
    }

    @media only screen and (min-width:320px) and (max-width:480px){
        .mySwiper{
            height:30vh;
        }
    
        img{
            object-fit: cover;
        }
    }

    @media only screen and (max-width: 320px){
        
        .mySwiper{
            height:20vh;
        }
    
        img{
            object-fit: cover;
        }
    }
`

export default Carousel