import React from 'react'
import Products from '../Components/Products'

const ProductsPage = () => {
  return (
    <div>
        <Products cat='Products'/>
    </div>
  )
}

export default ProductsPage