import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
const { REACT_APP_COMPANY_URL } = process.env;
const colorScheme = {
    primaryColor: '#172a88',
    secondaryColor: 'white'
}
const Service = () => {
    const { products } = useSelector((store) => (store.AppReducer));
    return (
        <Container colorScheme={colorScheme}>
            <div className='service'>
                <div className='ProductBoxHead'>
                    Services
                </div>
                <div className='productbox'>
                    {products?.map((ele, index) => (
                        ele.category === 'Services' &&
                        <div className='productdetails' key={index}>
                            <div className='detailsleft'>
                                <img src={`${REACT_APP_COMPANY_URL}${ele.img_path}`} alt={ele.name} />
                            </div>
                            <div className='detailsright'>
                                <h1>{ele.name} </h1>
                                <hr />
                                <p>{ele.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    )
}

const Container = styled.div`
    margin:80px 0px;
    width:100%;
    .service{
        width:80%;
        margin:auto;
    }
    
    .ProductBoxHead{
        text-align:left;
        background-color:${props => props.colorScheme.primaryColor};
        color:white; 
        border-radius:5px;
        text-align:center;
        font-size:x-large;
        font-weight:bold;
        padding:10px;
    }

    .service .productbox{
        width:100%;
        margin:auto;
        margin-top:10px;
    }

    .productbox > .productdetails{
        width:100%;
        display:flex;
        justify-content:center;
        gap:20px;
        margin-top:20px;
    }

    .productbox > .productdetails >div{
        width:50%;
    } 

    .productbox >:nth-child(even){
        flex-direction:row-reverse;
    } 


    .productbox .detailsleft img{
        width:100%;
        height:350px;
        object-fit:cover;
    }

    .productbox .detailsright{
        text-align:left;
        overflow:hidden;
    }

    .detailsright hr{
        background-color:#333;
    }

    .detailsright a{
        text-decoration:none;
        color:white;
        padding:12px 40px;
        background-color:${props => props.colorScheme.primaryColor};
        line-height:50px;
        border-radius:8px;
        text-transform:uppercase;
    }

    @media only screen and (min-width: 320px) and (max-width:1110px){
        .service{
            width:90%;
        }

        .productbox > .productdetails{
            width:100%;
            flex-direction:column;
        }

        .productbox > .productdetails >div{
            width:100%;
        } 

    }

    
`

export default Service