import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useSelector } from 'react-redux';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import emailjs from '@emailjs/browser'
import { Alert } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const colorScheme = {
    primaryColor: '#172a88',
    secondaryColor: 'white'
}
const ContactUs = () => {
    const [submit, setSubmit] = useState({
        status: false,
        msg: '',
        type: ''
    })
    const [data, setData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleSubmission = (e) => {
        e.preventDefault();
        if (data.name !== '' && data.email !== '' && data.subject !== '' && data.message !== '') {
            emailjs.send('service_po6idda', 'template_g5erg0q', data, 'Ob6YgTaYqeObolMjc')
                .then((response) => {
                    setSubmit({ ...submit, status: true, msg: 'Thank You For Contacting Us', type: 'success' });
                }).catch((error) => {
                    setSubmit({ ...submit, status: true, msg: 'There is some error occurs while sending message', type: "error" });
                    setData({
                        ...data,
                        name: '',
                        email: '',
                        subject: '',
                        message: ''
                    })
                });
        } else {
            setSubmit({ ...submit, status: true, msg: 'Please Fill All The Details', type: "error" });
        }
    };

    useEffect(() => {
        let id = setInterval(() => {
            setSubmit(false);
        }, 5000)

        if (!submit) {
            setData({
                ...data,
                name: '',
                email: '',
                subject: '',
                message: ''
            })
        };

        return () => {
            clearInterval(id)
        }

    }, [submit]);
    const { companyData } = useSelector((store) => (store.AppReducer))
    return (
        <Container colorScheme={colorScheme}>
            {submit.status && <Alert variant="filled" severity={submit.type} onClose={() => setSubmit(false)} style={{ zIndex: 1000, position: "fixed", top: '10%', left: '50%', transform: "translate(-50%, -50%)", margin: 0 }}>{submit.msg}</Alert>}
            <div className='contactcontainer'>
                <div className='ProductBoxHead'>
                    Contact Us
                </div>
                <div className='contacttop'>
                    <div className='contactdetailsbox'>
                        <a href={`tel:${companyData?.phone}`} target='_blank' rel='noreferrer'>
                            <p>TEL</p>
                            <span>{companyData?.phone}</span>
                        </a>
                        <div>
                            <span><PhoneAndroidIcon /></span>
                        </div>
                    </div>
                    <div className='contactdetailsbox'>
                        <a href={`https://wa.me/${companyData?.phone}`} target='_blank' rel='noreferrer'>
                            <p>Whatsapp</p>
                            <span>{companyData?.phone}</span>
                        </a>
                        <div>
                            <span><WhatsAppIcon /></span>
                        </div>
                    </div>

                    <div className='contactdetailsbox' style={{ fontSize: 'small' }}  >
                        <a href={`https://maps.app.goo.gl/8wAs5LAbaK9zZmgy7`} target='_blank' rel='noreferrer'>
                            <p>Location</p>
                            <span>{companyData?.address}</span>
                        </a>
                        <div>
                            <span><LocationOnIcon /></span>
                        </div>
                    </div>

                    <div className='contactdetailsbox'>
                        <a href={`mailto:${companyData?.contactemail}`} target='_blank' rel='noreferrer'>
                            <p>Email</p>
                            <span>{companyData?.contactemail}</span>
                        </a>
                        <div>
                            <span><MailOutlineIcon /></span>
                        </div>
                    </div>



                </div>
                <div className='contactbottom'>
                    <h1>Leave a Message</h1>
                    <p>When you come to this page, I believe that you have some trust and recognition of our products, and for more information about us, please do not hesitate to contact us. We look forward to hearing from you.</p>
                    <div className='contactForm'>
                        <form action="" onSubmit={handleSubmission}>
                            <div>
                                <label htmlFor="">Name*</label>
                                <input value={submit ? '' : data.name} type="text" name='name' placeholder='Name' onChange={(e) => setData({ ...data, name: e.target.value })} />
                                <label htmlFor="">Email*</label>
                                <input value={submit ? '' : data.email} type="email" name='email' placeholder='Email' onChange={(e) => setData({ ...data, email: e.target.value })} />
                                <label htmlFor="">Subject*</label>
                                <input value={submit ? '' : data.subject} type="text" name='subject' placeholder='Subject' onChange={(e) => setData({ ...data, subject: e.target.value })} />
                            </div>
                            <div>
                                <label htmlFor="">Leave a Message*</label>
                                <textarea value={submit ? '' : data.message} type="text" name='message' placeholder='Your Message' onChange={(e) => setData({ ...data, message: e.target.value })} />
                                <div>
                                    <button type='submit'>Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    )
}

const Container = styled.div`
    width:100%;
    margin:80px 0px;

    .ProductBoxHead{
        text-align:left;
        background-color:${props => props.colorScheme.primaryColor};
        color:white; 
        border-radius:5px;
        text-align:center;
        font-size:x-large;
        font-weight:bold;
        padding:10px;
    }
    
    .contactcontainer{
        width:80%;
        margin:auto;
    }

    .contacttop{
        margin-top:25px;
        display:grid;
        grid-template-columns: repeat(auto-fit,minmax(300px,max-content));
        gap:30px;
        justify-content:center;
    }

    .contactdetailsbox{
        height:100px;
        background-color:#eee;
        display:flex;
        justify-content:space-between;
        padding:10px 20px;
    }

    .contactdetailsbox >a{
        width:100%;
    }

    .contactdetailsbox >a:first-child{
        text-align:left;
    }

    .contactdetailsbox >div:last-child{
        text-align:right;
        width:100px;
        display:flex;
        padding:5px;
        align-items:flex-start;
        justify-content:flex-end;
    }

    .contactdetailsbox p{
        margin-bottom:5px;
        text-transform:uppercase;
    }

    .contactdetailsbox >div>span{
        text-align:right;
        width:60px;
        height:60px;
        border-radius:50px;
        display:flex;
        align-items:center;
        justify-content:center;
        border:1px solid #ccc;
    }

    .contactdetailsbox a{
        text-decoration:none;
        color:black;
    }

    
    .contactdetailsbox:hover{
        background-color:${props => props.colorScheme.primaryColor};
        color:white;
    }

    .contactdetailsbox:hover svg{
        color:black;
    }
    .contactdetailsbox:hover a{
        color:white;
    }
    .contactdetailsbox:hover div>span{
       background-color:white
    }

    .contactbottom{
        width:100%;
        margin-top:50px;
    }

    .contactbottom h1,.contactbottom p{
        text-align:left;
    }


    .contactForm>form{
        display:flex;
        gap:10px;
    }

    .contactForm label{
        text-align:left;
        line-height:30px;
    }
    .contactForm>form>div{
        display:flex;
        flex-direction:column;
        align-items:left;
        width:50%;
        text-align:left;
    }
    .contactForm>form>div>input{
        width:95%;
        padding:5px 5px 5px 15px;
        border:1px solid #ccc;
    }
    
    .contactForm>form>div:first-child>input{
        height:30px;
        margin-bottom:30px;
    }
    .contactForm>form>div:last-child>textarea{
        width:95%;
        height:235px;
        margin-bottom:30px;
        position:relative;
        padding:5px 5px 5px 10px;
        border:1px solid #ccc;
    }
    .contactForm>form>div:last-child>input::placeholder{
        position:absolute;
        top:15px;
    }

    .contactForm>form>div:last-child>div{
        display:flex;
        align-items:center;
        justify-content:flex-end;
    }
    .contactForm>form>div:last-child>div>button{
        background-color:${props => props.colorScheme.primaryColor};
        color:white;
        border:none;
        padding:10px 20px 10px 20px;
        cursor:pointer;
        margin-right:15px;
    }

    
    button:hover{
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }

    @media only screen and (min-width: 768px) and (max-width:1120px){
        .contactForm>form{
            flex-direction:column;
        }
        .contactForm>form>div{
            width:90%;
        }
        .contactForm>form{
            align-items:center;
        }
        .contactForm>form>div:last-child>div{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            padding-right:10px;
        }
        .contactForm>form>div:last-child>div>button{
            width:100%;
        }
    }
    
    @media only screen and (min-width: 481px) and (max-width:768px){
        .contactForm>form{
            flex-direction:column;
        }
        .contactForm>form>div{
            width:90%;
        }
        .contactForm>form{
            align-items:flex-start;
        }
        .contactForm>form>div:last-child>div{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            padding-right:10px;
        }
        .contactForm>form>div:last-child>div>button{
            width:100%;
        }
        .emailSent>h1{
            font-size:x-large;
        }
        .emailSent>p{
            width:80%;
            font-size:small;
        }

        
    }
    @media only screen and (min-width:320px) and (max-width:480px){
        .contactForm>form{
            flex-direction:column;
        }

        .contactForm>form>div{
            width:90%;
        }

        .contactForm>form{
            align-items:flex-start;
        }

        .contactForm>form>div:last-child>div{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-left:10px;
        }

        .contactForm>form>div:last-child>div>button{
            width:100%;
            
        }

        .endSection>p{
            font-size:15px;
        }

        .emailSent>h1{
            font-size:large;
        }

        .emailSent>p{
            width:70%;
            font-size:12px;
        }

        .contactbottom h1{
            font-size:larger;
        }

        .contactbottom p{
            font-size:small;
        }
    }
    @media only screen and (max-width: 320px){
        .contactForm>form{
            flex-direction:column;
        }
        
        .contactForm>form>div{
            width:90%;
        }

        .contactForm>form{
            align-items:center;
        }

        .contactForm>form>div:last-child>div{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-left:10px;
        }

        .contactForm>form>div:last-child>div>button{
            width:100%;
            margin-right:10px;
        }

        .endSection>p{
            font-size:10px;
        }

        .emailSent>h1{
            font-size:small;
        }

        .emailSent>p{
            width:70%;
            font-size:10px;
        }
    }
    @media only screen and (max-width: 319px){
        .contactForm>form{
            flex-direction:column;
        }

        .contactForm>form>div{
            width:90%;
        }

        .contactForm>form>div:last-child>div{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            padding-right:10px;
        }

        .contactForm>form>div:last-child>div>button{
            width:100%;
        }

        .endSection>p{
            font-size:8px;
        }
    }
`

export default ContactUs