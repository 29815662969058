export const GET_DATA_REQUEST = "GET_DATA_REQUEST";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_FAILURE = "GET_DATA_FAILURE";

export const GET_PRODUCT_DATA_REQUEST = "GET_PRODUCT_DATA_REQUEST";
export const GET_PRODUCT_DATA_SUCCESS = "GET_PRODUCT_DATA_SUCCESS";
export const GET_PRODUCT_DATA_FAILURE = "GET_PRODUCT_DATA_FAILURE";


export const POST_DATA_REQUEST = "POST_DATA_REQUEST";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";

export const PATCH_DATA_REQUEST = "PATCH_DATA_REQUEST";
export const PATCH_DATA_SUCCESS = "PATCH_DATA_SUCCESS";
export const PATCH_DATA_FAILURE = "PATCH_DATA_FAILURE";

export const DELETE_DATA_REQUEST = "DELETE_DATA_REQUEST";
export const DELETE_DATA_SUCCESS = "DELETE_DATA_SUCCESS";
export const DELETE_DATA_FAILURE = "DELETE_DATA_FAILURE";