import React, { useEffect, useState } from 'react';
import styledComp from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';
import { useNavigate } from 'react-router-dom';
import { editImages, getProducts, productDelete } from '../Redux/App/action';
import { FiEdit } from 'react-icons/fi';
import { useRef } from 'react';
import styled from 'styled-components';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "500px",
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};
const { REACT_APP_COMPANY_URL } = process.env;
const AllImagesData = ({ colorScheme }) => {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [product_id, setproduct_id] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((store) => (store.AuthReducer))
    const { products } = useSelector((store) => (store.AppReducer))
    const inputFile = useRef(false)
    const inputFile2 = useRef(false)
    const [data, setData] = useState({
        path: '',
        category: '',
        name: '',
        description: '',
        videoid: ''
    })

    const [show, setStatus] = useState({
        status: false,
        msg: '',
        type: 'error'
    });

    const handleOpen = (productid) => {
        setOpen(true)
        setproduct_id(productid)
    };

    const handleClose = () => setOpen(false);

    const handleEditOpen = (productid) => {
        setEditOpen(true)
        handleEditData(productid)
    };

    const handleEditClose = () => setEditOpen(false);

    const getProductData = () => {
        dispatch(getProducts()).then((res) => {
            if (!res.payload.status) {
                navigate("/", { replace: true })
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    const handleEditData = (productid) => {
        if (productid) {
            let product = products?.find((ele) => ele._id === productid);
            setData(product)
        }
    }

    const handleDataUpdate = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("category", data.category);
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("videoid", data.videoid);
        if (inputFile.current.value) {
            formData.append("image", inputFile.current.files[0]);
        }
        if (inputFile2.current.value) {
            formData.append("specimage", inputFile2.current.files[0]);
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        dispatch(editImages(data._id, formData, headers)).then((res) => {
            if (res.payload.msg === 'Data Updated Successfully') {
                setStatus({ ...show, status: true, msg: res.payload.msg, type: "success" });
                setTimeout(() => {
                    dispatch(getProducts()).then((res) => {
                        setEditOpen(false);
                    }).catch((err) => {
                        console.log(err)
                    });
                }, 2000)
            } else {
                setStatus({ ...show, status: true, msg: "Image is already in system", type: "error" });
            }
        })
    }

    const handleDelete = () => {
        if (product_id) {
            const payload = {
                id: product_id
            }
            const headers = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            dispatch(productDelete(payload, headers)).then((res) => {
                dispatch(getProducts())
                handleClose();
                if (res.payload.status) {
                    setStatus({ ...show, status: true, msg: res.payload.msg, type: "success" });
                } else {
                    setStatus({ ...show, status: true, msg: res.payload.msg, type: "error" });
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        getProductData();
    }, []);


    return (
        <ProductContainer colorScheme={colorScheme}>
            <table>
                <thead>
                    <tr>
                        <td>Image</td>
                        <td>Name</td>
                        <td>Category</td>
                        <td>Edit</td>
                        <td>Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {products?.map((ele, index) => (
                        <tr className={index % 2 === 0 ? 'userDetailsb' : 'userDetailsw'} key={index}>
                            <td><img src={`${REACT_APP_COMPANY_URL}${ele.img_path}`} alt={ele.name} width='150px' height='150px' /></td>
                            <td>{ele.name}</td>
                            <td>{ele.category}</td>
                            <td><FiEdit style={{ color: 'white', fill: 'black', fontSize: 'x-large' }} onClick={() => handleEditOpen(ele._id)} /></td>
                            <td ><span onClick={() => handleOpen(ele._id)}><DeleteIcon /></span></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are You Sure You Want to Delete This Product?
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', gap: '30px', mt: 5 }}>
                        <Button variant="contained" color="success" sx={{ gap: 1 }} onClick={handleDelete}><span>Delete</span> <CheckIcon /></Button>
                        <Button variant="contained" color="error" sx={{ gap: 1 }} onClick={handleClose}><span>Close</span><ClearIcon /></Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={editOpen}
                onClose={handleEditClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Container colorScheme={colorScheme}>
                        <div className='dashImageContainer'>
                            <div className='dashImageBox'>
                                <h1>Edit Data</h1>
                                <form >
                                    <div className='categoryBox'>
                                        <div>
                                            <h3>Choose Image:</h3>
                                            <input type="file" ref={inputFile} />
                                        </div>
                                        <div>
                                            <h3>Category*</h3>
                                            <select value={data?.category} name="" id="" onChange={(e) => setData({ ...data, category: e.target.value })}>
                                                <option value="">Select Category</option>
                                                <option value="Home Carousel1">Home Carousel1</option>
                                                <option value="We Provide Left">We Provide Left</option>
                                                <option value="We Provide Right">We Provide Right</option>
                                                <option value="We Provide Center">We Provide Center</option>
                                                <option value="Why Choose Us">Why Choose Us</option>
                                                <option value="Products">Products</option>
                                                <option value="About Top">About Top</option>
                                                <option value="About Bottom">About Bottom</option>
                                                <option value="Customized Machine">Customized Machine</option>
                                                <option value="Services">Services</option>
                                            </select>
                                        </div>

                                        <div>
                                            <h3>Name*</h3>
                                            <input value={data?.name} type="text" placeholder='Enter Name' onChange={(e) => setData({ ...data, name: e.target.value })} />
                                        </div>

                                        <div>
                                            <h3>Description:</h3>
                                            <input value={data?.description} type="text" placeholder='Enter Description' onChange={(e) => setData({ ...data, description: e.target.value })} />
                                        </div>

                                        {(data.category === 'Products' || data.category === 'Customized Machine') &&

                                            <>
                                                <div>
                                                    <h3>Youtube Video ID:</h3>
                                                    <input value={data?.videoid} type="text" placeholder='Enter Youtube Video ID' onChange={(e) => setData({ ...data, videoid: e.target.value })} />
                                                </div>
                                                <div>
                                                    <h3>Choose Specification:</h3>
                                                    <input type="file" ref={inputFile2} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <AlertMessage show={show} setStatus={setStatus} />
                    </Container>
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                        <Button variant="contained" color="success" sx={{ gap: 1 }} onClick={handleDataUpdate}><span>Save Changes</span> <CheckIcon /></Button>
                        <Button variant="contained" color="error" sx={{ gap: 1 }} onClick={handleEditClose}><span>Close</span><ClearIcon /></Button>
                    </Box>
                </Box>
            </Modal>
            <AlertMessage show={show} setStatus={setStatus} />
        </ProductContainer >
    )
}

const ProductContainer = styledComp.div`
    position:relative;
    width:100%;
    margin:auto;
    padding-top:30px;
    table {
        width: 100%;
        border-collapse: collapse;
        cursor:pointer;
    }
    thead{
        font-weight:bold;
        text-transform:uppercase;
    }
    td{
        padding:10px;
    }
    .userDetailsb td{
        background: #F8F8F8;
    }
    .userDetailsw td{
        background: #FFFFFF;
    }
    td svg{
        fill:${props => props.colorScheme.primaryColor};
    }
    
`

const Container = styled.div`
    position: relative;
    width:100%;
    margin:auto;
    padding:20px;

    .dashImageContainer{
        width:100%;
        margin:auto;
       
    }

    .dashImageBox{
        border-radius:8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        padding:10px 0px 30px 0px;
    }

    .dashImageBox h1{
        text-align:center;
    }

    .dashImageBox form{
        width:100%;
        display:flex;
        align-items:flex-start;
        justify-content:space-around;
        font-size:small;
    }

    input::file-selector-button {
        display: none;
    }

    .dashImageBox .categoryBox {
        display:flex;
        align-items:flex-start;
        flex-direction:column;
        line-height:0px;
        margin-top:-10px;
        gap:10px;
        text-align:left;
    }

    .dashImageBox .categoryBox select{
        padding:5px 20px;
        border-radius:8px;
        border:none;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-right: 16px solid transparent;
        display:flex;
    }

    .dashImageBox .categoryBox input{
        padding:5px 13px;
        border-radius:8px;
        border:none;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        display:flex;
    }

    .imageButtonSubmit{
        padding:15px 40px;
        border-radius:8px;
        border:none;
        font-weight:bold;
        background-color:${props => props.colorScheme.primaryColor};
        color:${props => props.colorScheme.secondaryColor};
    }

    @media only screen and (min-width:768px) and (max-width:1100px){
        width:100%;
        .dashImageContainer{
            width:100%;
        }
        
        .dashImageBox form{
            width:100%;
        }
    }

    @media only screen and (min-width:481px) and (max-width:767px){
        width:100%;

        .dashImageContainer{
            width:100%;
            margin:auto;
        }
        
        .dashImageBox form{
            width:100%;
            align-items:center;
            justify-content:center;
            gap:30px;
        }
        
        .dashImageBox form{
            flex-direction:column;
        }

        .imageFileBox{
            width:90%;
            height:200px;
            border:2px dashed grey;
            display:flex;
            align-items:center;
            justify-content:center;
            text-align:center;
        }
    }
      
    @media only screen and (min-width:320px) and (max-width:480px){
        width:100%;

        .dashImageContainer{
            width:100%;
            margin:auto;
        }
        
        .dashImageBox form{
            width:100%;
            align-items:center;
            justify-content:center;
            gap:30px;
        }
        
        .dashImageBox form{
            flex-direction:column;
        }

        .imageFileBox{
            width:100%;
            height:200px;
            border:2px dashed grey;
            display:flex;
            align-items:center;
            justify-content:center;
            text-align:center;
        }
    }
    
    @media only screen and (max-width: 320px){
        width:100%;

        .dashImageContainer{
            width:100%;
            margin:auto;
        }
        
        .dashImageBox form{
            width:100%;
            align-items:center;
            justify-content:center;
            gap:30px;
        }
        
        .dashImageBox form{
            flex-direction:column;
        }

        .imageFileBox{
            width:100%;
            height:200px;
            border:2px dashed grey;
            display:flex;
            align-items:center;
            justify-content:center;
            text-align:center;
        }
    }
`

export default AllImagesData;