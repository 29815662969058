import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components';
import { getProducts } from '../Redux/App/action';
import ProductsItem from './ProductsItem';
import SingleHeading from './SingleHeading';
const colorScheme = {
    primaryColor: '#172a88',
    secondaryColor: 'white'
}
const { REACT_APP_COMPANY_URL } = process.env;
const SingleProduct = () => {
    let { name } = useParams();
    const location = useLocation();
    const dispatch = useDispatch()
    const [data, setData] = useState({});
    const [productname, setproductname] = useState()
    let id = location?.state?.id;
    let cat = location?.state?.category;
    const { products, companyData } = useSelector((store) => (store.AppReducer));
    const [count, setCount] = useState(0)

    const getProductData = () => {
        dispatch(getProducts());
    }

    useEffect(() => {
        setproductname(name.split("-").join(" "))
    }, [name])



    useEffect(() => {
        // if (loc === 'home') {
        //     getProductData()
        //     console.log(productname)
        //     const product = products && products?.find((ele) => ele.name === productname && ele.category === 'Products')
        //     console.log(product)
        //     setData(product);
        // }
        if(products.length === 0){
            getProductData()
        }
        const product = products && products?.find((ele) => ((ele.name === productname && ele.category === 'Products') || (ele.name === productname && ele.category === 'Customized Machine')))
        setData(product);
    }, [name,products.length, data])

    useEffect(() => {
        products.forEach((ele) => {
            if (ele.category === cat) {
                setCount(prev => prev + 1);
            }
        })
    }, [products.length])

    return (
        <Container colorScheme={colorScheme} count={count}>
            <div className='productbox'>
                <div className='productdetails'>
                    <div className='detailsleft'>
                        <img src={`${REACT_APP_COMPANY_URL}${data?.img_path}`} alt={data?.name} />
                    </div>
                    <div className='detailsright'>
                        <h1>{data?.name} </h1>
                        <hr />
                        <p>{data?.description}</p>
                        <Link to={'/contact-us'}>
                            Inquiry
                        </Link>
                    </div>

                    <div className='logoProduct'>
                        <img src={`${REACT_APP_COMPANY_URL}${companyData?.img_path}`} alt={data?.name} width='40px' height='40px' />
                    </div>
                </div>

                {data?.videoid && <div className='videoBox'>
                    <SingleHeading heading={'Video Presentation'} />
                    <iframe src={`https://www.youtube.com/embed/${data?.videoid}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>}

                {data?.specimg_path && <div className='technicalparameters'>
                    <SingleHeading heading={'Technical Parameters'} />
                    <img src={`${REACT_APP_COMPANY_URL}${data?.specimg_path}`} alt={data?.name} />
                </div>}
                <div>
                    <SingleHeading heading={'Related Products'} />
                    <div className='productsBox'>

                        {products?.map((ele, index) => (
                            ele.category === 'Products' &&
                            <ProductsItem key={index} {...ele} companyData={companyData} />
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    )
}

const Container = styled.div`
    margin-top:80px;
    .productbox{
        width:80%;
        margin:auto;
    }

    .productbox>div{
        margin:20px 0px;
    } 

    .productbox .detailsleft{
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }

    .productdetails{
        position:relative;
        min-width:900px;
        display:grid;
        grid-template-columns: repeat(2,1fr);
        justify-content:center;
        gap:10px;
    }

    .productbox .detailsleft img{
        width:400px;
        height:350px;
        object-fit:contain;
    }

    .productbox .detailsright{
        text-align:left;
        padding:20px 30px;
        overflow:hidden;
    }

    .detailsright hr{
        background-color:#333;
    }

    .detailsright a{
        text-decoration:none;
        color:white;
        padding:12px 40px;
        background-color:${props => props.colorScheme.primaryColor};
        line-height:50px;
        border-radius:8px;
        text-transform:uppercase;
    }

    .videoBox iframe{
        width:100%;
        height:600px;
    }

    .technicalparameters img{
        width:70%;
        object-fit:contain;
    }

    .logoProduct{
        position:absolute;
        top:4%;
        left:2%;
    }

    .productsBox {
        margin-top:40px;
       display:grid;
       grid-template-columns: repeat(auto-fit,minmax(300px,max-content));
       gap:30px;
       justify-content:${props => props.count === 1 ? 'start' : 'space-evenly'};
       padding:20px;
       border-radius:8px;
    }

    @media only screen and (min-width: 320px) and (max-width:1110px){
        .productdetails{
            min-width:90%;
            grid-template-columns: repeat(1,2fr);
        }

        .technicalparameters img{
            width:100%;
        }
    
    }

    @media only screen and (min-width:320px) and (max-width:480px){
        .productbox{
            width:90%;
        }

        .productbox .detailsright{
            padding:20px 0px;
        }

        .videoBox iframe{
            width:100%;
            height:300px;
        }

        .productdetails{
            min-width:90%;
            grid-template-columns: repeat(1,2fr);
        }

        .productbox .detailsleft img{
            width:300px;
            height:250px;
            object-fit:contain;
        }

    }
`

export default SingleProduct