import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCompanyData, postCompanyData } from '../Redux/App/action';
import AlertMessage from './AlertMessage';

const CompanyInfo = ({ colorScheme }) => {
    const { REACT_APP_IMAGE_URL } = process.env;
    const { token } = useSelector((store) => (store.AuthReducer));
    const { companyData } = useSelector((store) => (store.AppReducer));
    const { img_path, companyFullName, companyName, address, cin, gstin, phone, contactemail, purchaseEmail, iecNo } = companyData
    const dispatch = useDispatch();
    const [data, setData] = useState({
        companyFullName: '',
        companyName: '',
        address: '',
        cin: '',
        gstin: '',
        phone: '',
        contactemail: '',
        purchaseEmail: '',
        iecNo: ''
    })

    const [show, setStatus] = useState({
        status: false,
        msg: '',
        type: 'error'
    });

    const handleCompanyDetails = () => {
        const payload = {
            companyFullName: data.companyFullName ? data.companyFullName : companyFullName,
            companyName: data.companyName ? data.companyName : companyName,
            address: data.address ? data.address : address,
            cin: data.cin ? data.cin : cin,
            gstin: data.gstin ? data.gstin : gstin,
            phone: data.phone ? data.phone : phone,
            contactemail: data.contactemail ? data.contactemail : contactemail,
            purchaseEmail: data.purchaseEmail ? data.purchaseEmail : purchaseEmail,
            iecNo: data.iecNo ? data.iecNo : iecNo,
            img_path:'/resources/images/omme-logo.png'
        }
        const headers = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        dispatch(postCompanyData(payload,headers)).then((res=>{
            if(res.payload.status){
                setStatus({ ...show, status: true, msg: res.payload.msg, type: "success" });
            }else{
                setStatus({ ...show, status: true, msg: res.payload.msg, type: "error" });
            }
        })).catch((err)=>{
            console.log(err)
        })
    }

    const getData = () => {
        dispatch(getCompanyData())
    }


    useEffect(() => {
        getData();
    }, []);

    return (
        <Container colorScheme={colorScheme}>
            <div className='companyInfoBox'>
                <div className='companyInfo'>
                    <div className='companyInfoHead'>
                        <img src={`${REACT_APP_IMAGE_URL}${img_path}`} alt="" />
                        <div>
                            <h2>{companyFullName}</h2>
                            <p>{companyName}</p>
                        </div>
                    </div>

                    <div className='companyInfoDetailsContainer'>
                        <div className='companyInfoDetails'>
                            <h3>Details:-</h3>
                            <div className='detailedInfo'>
                                <span>Address:- <span>{address}</span></span>
                                <span>CIN No:- <span>{cin}</span></span>
                                <span>GSTIN No:- <span>{gstin}</span></span>
                                <span>IEC No:- <span>{iecNo}</span></span>
                                <span>Email:- <span>{contactemail}</span></span>
                                <span>Purchase Email:- <span>{purchaseEmail}</span></span>
                                <span>Phone:- <span>{phone}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='companyDataBox'>
                <h2>You Can Change Company Details From Here</h2>
                <span></span>
                <div className='companyInfoDataBox'>
                    <div>
                        <label htmlFor="">Company Full Name</label>
                        <input type="text" placeholder='Enter Company Full Name' onChange={(e) => setData({ ...data, companyFullName: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">Company Name</label>
                        <input type="text" placeholder='Enter Company Name' onChange={(e) => setData({ ...data, companyName: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">Address</label>
                        <input type="text" placeholder='Enter Address' onChange={(e) => setData({ ...data, address: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">CIN No.</label>
                        <input type="text" placeholder='Enter CIN No.' onChange={(e) => setData({ ...data, cin: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">GSTIN No.</label>
                        <input type="text" placeholder='Enter GSTIN No.' onChange={(e) => setData({ ...data, gstin: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">Phone No.</label>
                        <input type="number" placeholder='Enter Phone No.' onChange={(e) => setData({ ...data, phone: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">Email</label>
                        <input type="text" placeholder='Enter Email' onChange={(e) => setData({ ...data, contactemail: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">Purchase Email</label>
                        <input type="text" placeholder='Enter Purchase Email' onChange={(e) => setData({ ...data, purchaseEmail: e.target.value })} />
                    </div>

                    <div>
                        <label htmlFor="">IEC NO.</label>
                        <input type="text" placeholder='Enter IEC NO.' onChange={(e) => setData({ ...data, iecNo: e.target.value })} />
                    </div>
                </div>
                <button onClick={handleCompanyDetails}>Change Details</button>
            </div>
            <AlertMessage show={show} setStatus={setStatus} />
        </Container>
    )
}

const Container = styled.div`
    position:relative;
    width:100%;
    padding:20px 30px;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    gap:30px;
    
    .companyInfoBox, .companyDataBox{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        padding-top:10px;
        padding-bottom:40px;
        border-radius:8px;
    }

    .companyInfoBox{
        align-items:flex-start;
        padding:20px;
    }

    .companyInfoHead{
        display:flex;
        gap:10px;
    }

    .companyInfoHead > div{
        display:flex;
        align-items:flex-start;
        flex-direction:column;
        text-align:left;
        padding: 0px 10px;
    }

    .companyInfoHead p{
        line-height:0px;
        margin:0px;
    }

    .companyInfoHead > img{
        width:120px;
        height:120px;
    }

    .companyInfoDetailsContainer{
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius:8px;
        margin-top:20px;
        text-align:left;
        padding:10px;
    }

    .companyInfoDetails{
        text-align:left;
        padding:0px 20px;
    }

    .detailedInfo{
        display:flex;
        flex-direction:column;
        gap:5px;
    }

    .detailedInfo span{
        font-weight:bold;
    }

    .detailedInfo span>span{
        font-weight:normal;
        padding-left:10px;
    }

    .companyDataBox h2{
        width:95%;
        text-align:left;
        padding:0px 10px;
    }

    .companyDataBox span{
        width:100%;
        border-top: 1px solid #eee;
    }


    .companyDataBox button{
        margin-top:30px;
        padding:15px 30px;
        background-color:${props => props.colorScheme.primaryColor};
        border:1px solid ${props => props.colorScheme.primaryColor};
        border-radius:5px;
        color:white;
        cursor:pointer;
        font-weight:bold;
    }

    .companyInfoDataBox{
        width:90%;
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(auto-fit,minmax(200px,max-content));
        justify-content:center;
        margin:auto;
        margin-top:10px;
        
    }

    .companyInfoDataBox div{
        display:flex;
        align-items:flex-start;
        flex-direction:column;
        text-align:left;
        padding:10px;
    }

    .companyInfoDataBox label{
        font-weight:bold;
    }

    .companyInfoDataBox input{
        margin-top:10px;
        padding:10px 15px;
        border-radius:8px;
        border:none;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        
    }

    .companyInfoDataBox input::placeholder{
        color:#c5c4c4;
    }

    @media only screen and (min-width:481px) and (max-width:767px){
        .companyInfoBox{
            font-size:small;
        }
    }
      
    
    @media only screen and (min-width:320px) and (max-width:480px){
        .companyInfoBox{
            font-size:x-small;
        }

        .companyInfoBox img{
            height:80px;
            width:80px;
        }

        .companyDataBox h2{
            font-size:16px;
        }

        .companyInfoDetails{
            text-align:left;
            padding:0px 5px;
        }
    }
    
    @media only screen and (max-width: 320px){
        .companyInfoBox{
            font-size:x-small;
        }

        .companyInfoBox img{
            height:80px;
            width:80px;
        }

        .companyDataBox h2{
            font-size:15px;
        }

        .companyInfoDetails{
            text-align:left;
            padding:0px 5px;
        }
    }
`


export default CompanyInfo