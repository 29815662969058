import React from 'react'
import styled from 'styled-components';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HailIcon from '@mui/icons-material/Hail';
import InsightsIcon from '@mui/icons-material/Insights';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
const { REACT_APP_COMPANY_URL } = process.env;
const Stats = () => {
    const img_path = `/resources/images/stats-bg.jpg`;
    return (
        <Container>
            <img src={`${REACT_APP_COMPANY_URL}${img_path}`} alt="stats-bg" className='stats-img'/>
            <div className='statsBox'>
                <div>
                    <span><ApartmentIcon/> 2012</span>
                    <div></div>
                    <span>Established In</span>
                </div>
                <div>
                    <span><HailIcon/> 75</span>
                    <div></div>
                    <span>No of Employees</span>
                </div>
                <div>
                    <span><InsightsIcon/> All Over India</span>
                    <div></div>
                    <span>Sell To The Country</span>
                </div>
                <div>
                    <span><VolunteerActivismIcon/> 100</span>
                    <div></div>
                    <span>Service Customer</span>
                </div>
            </div>
        </Container>
    )
}

const Container = styled.div`
    width:100%;
    
    position:relative;
    .stats-img{
        width:100%;
    }
    .statsBox{
        width:100%;
        display:grid;
        grid-template-columns: repeat(auto-fit,minmax(150px,max-content));
        align-items:center;
        justify-content:space-evenly;
        position:absolute;
        top:30%;
        color:white;
        font-size:x-large;
        gap:50px;
        font-size:large;
    }

    .statsBox > div{
        
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        gap:2px;
    }

    .statsBox > div > div{
        width:100%;
        display:flex;
    }

    .statsBox > div > div:before{
        width:100%;
        content: "";
        border: 1px solid #d3d3d3;
        align-self: stretch;
        background-color:white;
    }
    

    .statsBox > div > span{
        display:flex;
        align-items:center;
        justify-content:center;
        gap:10px;
    }

    .statsBox > div > div+span{
       font-size:large;
    }

    @media only screen and (min-width:481px) and (max-width:798px){
        .stats-img{
            height:250px;
        }

        .statsBox{
            top:15%;
            align-items:center;
            justify-content:center;
            font-size:large;
        }
    }

    @media only screen and (min-width:378px) and (max-width:481px){
        .stats-img{
            height:250px;
        }

        .statsBox{
            width:95%;
            top:12%;
            left:3%;
            align-items:center;
            justify-content:center;
            font-size:large;
        }
    }
          
    @media only screen and (min-width:319px) and (max-width:378px){
        .stats-img{
            height:500px;
        }
        .statsBox{
            width:95%;
            top:10%;
            left:3%;
            align-items:center;
            justify-content:center;
            
        }
    }

    @media only screen and (max-width: 319px){
        .stats-img{
            height:500px;
        }
        .statsBox{
            width:95%;
            top:10%;
            left:5%;
            align-items:center;
            justify-content:center;
            
        }
    }    
`

export default Stats