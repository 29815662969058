import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import ProductsItem from './ProductsItem';



const colorScheme = {
    primaryColor: '#172a88',
    secondaryColor: 'white'
}
const meta = {
    title: 'Some Meta Title',
    description: 'I am a description, and I can create multiple tags',
    canonical: 'http://example.com/path/to/page',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
}
const Products = ({ cat }) => {
    const { products, companyData } = useSelector((store) => (store.AppReducer));
    const [category, setCategory] = useState('');
    const [count , setCount] = useState(0)
    
    
    useEffect(()=>{
        let keyMeta = document.getElementById("metakeyword");
       document.title= "Omme Electromech | All Product";
        keyMeta.setAttribute("content","Paper Insertion Machine, Slot Paper Insertion Machine, Armature Paper Insertion, Stator Paper Insertion, Commutator Pressing Machine, Armature Winding Machine, Double Flyer Armature Winding Machine, Wedge Insertion Machine, Hot Stacking Machine, Commutator Fusing Machine, Armature Fusing Machine, Armature Trickling Machine, Armature Varnishing Machine, Commutator Turning Machine, Armature Balancing Machine, Auto Positioning Balancing Machine, 2 Station Automatic Balancing Machine, 5 Station Automatic Balancing Machine, Stator Winding Machine, Automatic Stator Winding Machine, Armature Testing Machine, Stator Testing Machine, Final Armature Tester, BLDC Winding Machine, Internal BLDC Winding Machine, External BLDC Winding Machine, BLDC Fan winding Machine, BLDC Motor Winding Machine, E-Rickshaw BLDC Motor Winding Machine");
        setCategory(cat);
      

    },[])

    useEffect(()=>{
        products.forEach((ele)=>{
            if(ele.category === cat){
                setCount(prev => prev+1);
            }
        })
    },[products.length])
    return (
        <Container colorScheme={colorScheme} count={count}>
            <div className='productsContainer'>
                <div className='ProductBoxHead'>{category} Center</div>
                <div className='productsBox'>
                    {products?.map((ele, index) => (
                        ele.category === cat &&
                        <ProductsItem key={index} {...ele} companyData={companyData} category={category} />
                    ))}
                </div>
               
            </div>
            
        </Container>
        
    )
}
const Container = styled.div`
    width:100%; 
    margin:auto;
    margin-top:100px;
    margin-bottom:50px;
    .productsContainer{
        width:85%;
        margin:auto;
    }

    .productsContainer .ProductBoxHead{
        text-align:left;
        padding:0px 15px;
        background-color:${props => props.colorScheme.primaryColor};
        padding:15px 15px;
        font-size:x-large;
        color:white; 
        border-radius:5px;
    }

    .productsBox {
       margin-top:40px;
       display:grid;
       grid-template-columns: repeat(auto-fit,minmax(300px,max-content));
       gap:30px;
       justify-content:${props=>props.count === 1?'start':'space-evenly'};
       padding:20px;
       border-radius:8px;
    }
   
`

export default Products