import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import { getProducts } from './Redux/App/action';
import AllRoutes from './Routes/AllRoutes';
import loading from './Resources/loading.gif'

function App() {
  const dispatch = useDispatch()
  const location = useLocation();
  const [isLoading, setLoading] = useState(false)
  const getProductData = () => {
    dispatch(getProducts())
  }

  useEffect(() => {
    getProductData()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },2000)
  }, [location.pathname])

  return (
    <div className="App">
      {isLoading ?
        <Box sx={{ display: 'flex', alignItems:'center', justifyContent:'center', height:'90vh' }}>
          {/* <CircularProgress /> */}
          <img src={loading} alt='loading' height={'100px'} width='100px'/>
        </Box>
        :
        <>
          {location.pathname !== '/admin/dashboard' && location.pathname !== '/login' && location.pathname !== '/signup' ? <Navbar /> : null}
          <AllRoutes />
          {location.pathname !== '/admin/dashboard' && location.pathname !== '/login' && location.pathname !== '/signup' ? <Footer /> : null}
        </>
      }
    </div>
  );
}

export default App;
