import React from 'react'
import styled from 'styled-components'
const colorScheme = {
    primaryColor: '#172a88',
    secondaryColor: 'white'
}
const SingleHeading = ({heading}) => {
    return (
        <Container colorScheme={colorScheme }>
            <div className='singleHead'>
                <span></span>
                <p>{heading}</p>
            </div>
        </Container>
    )
}
const Container = styled.div`
    width:100%;
    height:50px;
    display:flex;
    margin-bottom:20px;
    

    .singleHead{
        width:100%;
        height:50px;
        background-color:#f3f3f3;
        display:flex;
        align-items:flex-start;
        gap:20px;
        border-radius: 0px 0px 20px 0px;
    }

    span{
        width:15px;
        height:50px;
        background-color:${props=>props.colorScheme.primaryColor}

    }

    p{
        text-transform:uppercase;
    }
`

export default SingleHeading