import { Link } from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

const { REACT_APP_COMPANY_URL } = process.env;
const ProductsItem = ({ _id, img_path, description, name, companyData, category }) => {
    let cat = category.toLowerCase().replace(/\s+/g, '-');
    if(window.location.pathname.split('/').length>2)
    {
        let product= window.location.pathname.split('/')[2];
        document.title = product.replace(/-/g," ")+" | Omme Electromech";
       let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Omme Electromech Private Limited -  is a leading Exporter, Importer, Manufacturer, Supplier of  ' + product +' in Ghaziabad, Noida, Delhi, Uttar Pradesh, India');
        let keyMeta = document.querySelector("meta[id='metakeyword']");
        keyMeta.setAttribute(
            "content", product +"," +product +" Manufacturers & Suppliers in india,"+"Best "+product +" in India," +product + "  from Noida, Uttar Pradesh, India, " + product +" Manufacturers & Suppliers in Ghaziabad, Noida, Delhi, Uttar Pradesh, India");
    }
    return (


        <Container>
            <Link to={`/${cat}/${name.replace(/\s+/g, '-')}`} state={{ id: _id, category: category }}>
                <div className='productImage'>
                    <img src={`${REACT_APP_COMPANY_URL}${img_path}`} alt={name} />
                </div>
                <div className='productName'>
                    <h3>{name}</h3>
                </div>
                <div className='logoProduct'>
                    <img src={`${REACT_APP_COMPANY_URL}${companyData?.img_path}`} alt={name} width='40px' height='40px' />
                </div>
            </Link>
        </Container>
    )

}
const Container = styled.div`
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    position:relative;
    font-size:small;

    .productImage>img{
        width:250px;
        height:250px;
        object-fit:contain;
    }

    .logoProduct{
        position:absolute;
        top:5%;
        left:5%;
    }

    a{
        text-decoration:none;
        color:black;
    }
`
export default ProductsItem