import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
const { REACT_APP_COMPANY_URL } = process.env;
const colorScheme = {
  primaryColor: '#172a88',
  secondaryColor: 'white'
}
const Aboutus = () => {
  const { products } = useSelector((store) => (store.AppReducer));
  const img_path = `/resources/images/stats-bg.jpg`;
  
  //---------------------------------------------------------

  

  //--------------------------------------

  return (
    <Container colorScheme={colorScheme}>
      <div className='aboutus'>
        <div className='ProductBoxHead'>
          About Us
        </div>
        {products?.map((ele, index) => (
          (ele.category === 'About Top' || ele.category === 'About Bottom') &&
          <div className='aboutbox' key={index}>
            <div>
              <p>{ele.description}</p>
            </div>
            <div>
              <img src={`${REACT_APP_COMPANY_URL}${ele?.img_path}`} alt={ele.name} />
            </div>
          </div>
        ))}
      </div>
      <div className='aboutendBox'>
        <img src={`${REACT_APP_COMPANY_URL}${img_path}`} alt='quote' />
        <p>"Struggling-oriented, team-oriented, sharing gratitude" is the value of Omme Electromech Pvt. Ltd.; Omme Electromech will continue to uphold the corporate tenet of "customer-centric, meet customer needs", and continue to consolidate and improve the Omme Electromech technology in the same industry at home and abroad Leading advantages, comprehensively enhance the company's comprehensive competitiveness and brand influence, contribute to the development of my country's Motor machine manufacturing industry, and let India's manufacturing go to the world!</p>
      </div>
    </Container>
  )
}

const Container = styled.div`
  
  .aboutus{
    margin:auto;
    margin-top:120px;
    width:85%;
    display:grid;
    grid-template-columns:repeat(1,2fr);
  }

  .ProductBoxHead{
    text-align:left;
    background-color:${props => props.colorScheme.primaryColor};
    color:white; 
    border-radius:5px;
    text-align:center;
    font-size:x-large;
    font-weight:bold;
    padding:10px;
  }

  .aboutbox{
    margin-top:50px;
    display:flex;
    align-items:flex-start;
    gap:20px;
    text-align:justify;
  }

  .aboutbox:nth-child(odd){
    flex-direction:row-reverse;
  }

  .aboutbox > div{
   max-width:50%;
  }

  .aboutbox > div p{
    line-height:25px;
    letter-spacing:0.5px;
    margin-top:0px;
  }

  .aboutbox >div:last-child{
    width:100%;
    height:400px;
  }

  .aboutbox img{
    width:100%;
    height:100%;
    object-fit:cover;
  }

  .aboutendBox{
    margin:50px 0px;
    position:relative;
  }

  .aboutendBox p{
    width:80%;
    left:10%;
    position:absolute;
    top:28%;
    color:white;
  }

  @media only screen and (min-width: 320px) and (max-width:1110px){
    .aboutbox > div{
      min-width:90%;
    }

    .aboutbox{
     flex-direction:column-reverse;
     align-items:center;
    }

    .aboutbox:nth-child(odd){
      flex-direction:column-reverse;
    }

    .aboutbox >div:last-child{
      width:100%;
      height:auto;
    }
  
    .aboutbox img{
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }

  @media only screen and (min-width: 798px) and (max-width:1110px){

    .aboutendBox p{
        top:18%;
    }
  }

  @media only screen and (min-width:481px) and (max-width:798px){
    .aboutendBox img{
        height:350px;
    }

    .aboutendBox p{
        top:15%;
    }
  }

@media only screen and (min-width:378px) and (max-width:481px){
  .aboutendBox img{
    height:400px;
  }

  .aboutendBox p{
      top:10%;
  }
}
      
@media only screen and (min-width:320px) and (max-width:378px){
  .aboutendBox img{
    height:500px;
  }

  .aboutendBox p{
      top:5%;
  }
}

@media only screen and (max-width: 320px){
    .aboutendBox{
        height:250px;
    }

    .aboutendBox p{
        top:15%;
        align-items:center;
        justify-content:center;
    }
}    

`

export default Aboutus