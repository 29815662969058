import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom'
const { REACT_APP_COMPANY_URL } = process.env;
const Footer = () => {
    const { companyData, products } = useSelector((store) => (store.AppReducer));


    return (
        <Container>
            <div className='footerBox'>
                <div className='footerTop'>
                    <div>
                        <img src={`${REACT_APP_COMPANY_URL}${companyData?.img_path}`} alt="logo" width='70px' height='70px' />
                        <p>{companyData.companyFullName}</p>
                    </div>
                    <div className='contactBox'>
                        <div>
                            <span><PhoneAndroidIcon />Phone:</span>
                            <a href={`tel:${companyData?.phone}`} ><span>{companyData?.phone}</span></a>
                        </div>
                        <div>
                            <span><EmailIcon />Email:</span>
                            <a href={`mailto:${companyData?.contactemail}`} ><span>{companyData?.contactemail}</span></a>
                        </div>
                    </div>
                    <div className='addressBox'>
                        <a href='https://maps.app.goo.gl/8wAs5LAbaK9zZmgy7'><PhoneAndroidIcon />Address: <p>{companyData?.address}</p></a>
                    </div>
                </div>
                <Divider sx={{ backgroundColor: 'rgb(66 69 82)' }} />
                <div className='footerbottom'>
                    <div className='footerProductsBox'>
                        <h1>Products</h1>
                        <div className='footerProducts'>
                            {products.map((ele, index) => (
                                ele.category === 'Products' &&
                                <Link key={index} to={`/products/${ele.name.replace(/\s+/g, '-')}`} state={{ id: ele._id }}>
                                    <span >{ele.name}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className='footerProductsBox'>
                        <h1>About Us</h1>
                        <div className='footerProducts'>
                            <Link to={'/about-us'}>
                                <span >Company Profile</span>
                            </Link>
                            <Link to={'/about-us'}>
                                <span >Company Certification</span>
                            </Link>
                            <Link to={'/about-us'}>
                                <span >Company tour</span>
                            </Link>
                            <Link to={'/about-us'}>
                                <span >Partner Support</span>
                            </Link>
                            <Link to={'/contact-us'}>
                                <span >Contact Us</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Container >
    )
}

const Container = styled.div`
    width:100%;
    margin:auto;
    background-color:#2d303e;

    .footerBox{
        margin:auto;
        padding:30px 0px;
        font-size:small;
    }

    .footerTop{
        width:75%;
        margin:auto;
        display:flex;
        align-items:center;
        justify-content:space-around;
        padding:10px 0px;
    }

    .footerTop>div{
        width:30%;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        justify-content:center;
        color:white;
    }

    .footerTop>div>div {
        display:flex;
        align-items:flex-start;
        justify-content:space-around;
        color:white;
        gap:10px;
    }

    .contactBox{
        gap:15px;
    }

    .contactBox a{
        text-decoration:none;
        color:white;
    }

    .contactBox>div>span{
        display:flex;
        align-items:flex-start;
        justify-content:space-around;
        gap:10px;
    }

    .addressBox{
        width:50%;
    }

    .addressBox a{
        display:flex;
        align-items:flex-start;
        gap:10px;
        text-decoration:none;
        color:white;
    }

    .addressBox p{
        display:flex;
        align-items:flex-start;
        font-size:12px;
        margin-top:0px;
        text-align:left;
    }

    .footerbottom{
        width:75%;
        margin:auto;
        display:flex;
        align-items:flex-start;
        color:white;
    }

    .footerbottom>div{
        width:100%;
        padding:0px 10px;
        text-align:left;
    }

    .footerProducts{
        display:grid;
        grid-template-columns: repeat(auto-fit,minmax(250px,max-content));
        text-align:left;
        gap:10px;
        font-size:16px;
        color:grey;
    }

    .footerProducts a{
       text-decoration:none;
       color:grey;
    }

    @media only screen and (min-width:768px) and (max-width:850px){
        .footerTop{
            width:100%;
            flex-direction:column;
            gap:20px;
            margin:auto;
        }

        .footerTop>div{
            width:90%;
            align-items:flex-start;
        }

        .footerTop>div:first-child{
            flex-direction:row;
            align-items:center;
            justify-content:left;
            gap:10px;
            
        }

        .footerbottom{
            width:100%;
            flex-direction:column;
            gap:30px;
            align-items:flex-start;
            padding:10px;
         }
 
         .footerbottom h1{
            text-align:left;
         
         }
 
         .footerProducts{
            align-items:flex-start;
            text-align:left;
         }
    }
    
    @media only screen and (min-width:481px) and (max-width:767px){
        .footerTop{
            width:100%;
            flex-direction:column;
            gap:20px;
            margin:auto;
        }

        .footerTop>div{
            width:90%;
            align-items:flex-start;
        }

        .footerTop>div:first-child{
            flex-direction:row;
            align-items:center;
            justify-content:left;
            gap:10px;
            
        }

        .footerbottom{
            width:100%;
            flex-direction:column;
            gap:30px;
            align-items:flex-start;
            padding:10px;
         }
 
         .footerbottom h1{
            text-align:left;
         
         }
 
         .footerProducts{
            align-items:flex-start;
            text-align:left;
         }
    }
          
    @media only screen and (min-width:320px) and (max-width:480px){
       .footerTop{
            width:100%;
            flex-direction:column;
            gap:20px;
            margin:auto;
        }

        .footerTop>div{
            width:90%;
            align-items:flex-start;
        }

        .footerTop>div:first-child{
            flex-direction:row;
            align-items:center;
            justify-content:left;
            gap:10px;
            
        }

        .footerbottom{
            width:100%;
            flex-direction:column;
            gap:30px;
            align-items:flex-start;
            padding:10px;
         }
 
         .footerbottom h1{
            text-align:left;
         
         }
 
         .footerProducts{
            align-items:flex-start;
            text-align:left;
         }
    }

    @media only screen and (max-width: 320px){
        .footerTop{
            width:100%;
            flex-direction:column;
            gap:20px;
            margin:auto;
        }

        .footerTop>div{
            width:90%;
            align-items:flex-start;
        }

        .footerTop>div:first-child{
            flex-direction:row;
            align-items:center;
            justify-content:left;
            gap:10px;
            
        }

        .footerbottom{
            width:100%;
            flex-direction:column;
            gap:30px;
            align-items:flex-start;
            padding:10px;
         }
 
         .footerbottom h1{
            text-align:left;
         
         }
 
         .footerProducts{
            align-items:flex-start;
            text-align:left;
         }
    }    

`

export default Footer